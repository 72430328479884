<template>
  <div class="pageBox">
    <div class="content">
      <h3 class="title">绑定厂商账号</h3>
      <div class="membersBox">
        <h4 class="msg">请先绑定厂商账号</h4>
        <div class="membersContent">
          <ul class="members">
            <li class="item" v-for="(item,index) of members" :key="index">
              <h3 class="memberName">
                <!-- 小米svg 阿里图标有问题，采用本地 -->
                <svg-icon v-if="item.memberCode=='xiaomi'" class="xiaomiLogo" svg-name="xiaomiLogo"></svg-icon>
                <svg v-if="item.memberCode=='OPPO'" class="OPPOLogo" aria-hidden="true">
                  <use xlink:href="#iconoppo" />
                </svg>
                <svg v-if="item.memberCode=='vivo'" class="vivoLogo" aria-hidden="true">
                  <use xlink:href="#iconvivo" />
                </svg>
                <svg v-if="item.memberCode=='lenovo'" class="lenovoLogo" aria-hidden="true">
                  <use xlink:href="#iconlenovo" />
                </svg>
              </h3>
              <!-- 1-未绑定 2-已绑定  -->
              <div v-if="item.bindStatus==2" class="memberBindBox">
                <div class="memberUser">
                  <span class="text">账号：{{item.memberUserName}}</span>
                  <span class="btn" @click="memberUnbind(item)">解除绑定</span>
                </div>
              </div>
              <div v-else class="memberBindBox">
                <div class="memberBind">
                  <svg class="bindIcon" aria-hidden="true" @click="memberOauthUrl(item)">
                    <use xlink:href="#icon2" />
                  </svg>
                  <p>绑定{{item.memberName}}开放平台账号</p>
                </div>
                <div class="tipBox">
                  <p v-if="item.type==1" class="must">*必须绑定</p>
                  <p v-if="item.type==2" class="color-999">选择性绑定</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { singleMembersSever } from "@/common/api-config";
export default {
  created() {
    if (
      !sessionStorage.getItem("isLookBind") &&
      !this.$store.getters["membersStore/isAllBind"]
    ) {
      //进入前看：是否看过提示（字符串类型的false && 没有全部绑定厂商
      this.$msgBox
        .alertMessageBox({
          //弹出提示
          title: "绑定厂商账号",
          content: "绑定所有厂商开放平台的账号后，才能进行金标认证",
          btnText: "前往绑定",
        })
        .then(async (data) => {
          sessionStorage.setItem("isLookBind", true); //存本地缓存：看过提示
        })
        .catch((val) => {});
    } else {
      if (this.$route.query.state && this.$route.query.code) {
        //跳转厂商绑定页面，绑定成功跳转回来带上的参数
        this.memberBind(this.$route.query.state, this.$route.query.code); //执行本地绑定
      }
    }
  },
  data() {
    return {};
  },
  computed: {
    members() {
      //厂商list
      return this.$store.getters["membersStore/members"];
    },
  },
  methods: {
    //拼接绑定URL跳转地址
    getRedirectUri() {
      let redirect_uri = location.protocol + "//" + location.hostname;
      if (location.port) {
        redirect_uri += ":" + location.port;
      }
      redirect_uri += "/console/allianceMembers/bind";
      return redirect_uri;
    },
    //跳转厂商绑定账户页面
    memberOauthUrl(item) {
      this.$msgBox
        .alertMessageBox({
          content: "请确保您绑定的厂商账户已经发布了需要金标认证的应用",
          btnText: "前往绑定",
        })
        .then(async (data) => {
          singleMembersSever
            .memberOauthUrl({
              memberCode: item.memberCode, //	必选	string	厂商编码
            })
            .then((res) => {
              if (res.code == 0) {
                const redirect_uri = encodeURIComponent(this.getRedirectUri()); //拼接绑定URL跳转地址
                this.url = `${res.data.oauthUrl}&redirect_uri=${redirect_uri}&state=${item.memberCode}`;
                window.location.href = this.url;
              } else {
                this.$message.error(res.message);
              }
            });
        })
        .catch((val) => {});
    },
    //跳转绑定厂商页面，完成绑定后回来，触发本地绑定
    memberBind(state, code) {
      let arr = utils.filterArr(this.members, "memberCode", state); //过滤出某个厂商数据
      /// 过滤出了某厂商 && 未绑定
      if (arr.length && arr[0].bindStatus != 2) {
        singleMembersSever
          .accountBind({
            state: state, //	必选	string	厂商编码
            code: code, //	必选	string	用于识别厂商回调以后返回的code属于哪个平台
          })
          .then((res) => {
            if (res.code == 0) {
              this.$msgBox
                .alertMessageBox({
                  content: "您的账户已经绑定成功",
                })
                .then(async (data) => {
                  this.$store.dispatch("membersStore/updateMembers", {}); //更新厂商绑定数据
                })
                .catch((val) => {});
            } else {
              this.$msgBox
                .alertMessageBox({
                  content: res.message,
                })
                .then(async (data) => {
                  this.$router.replace("/console/allianceMembers/bind");
                })
                .catch((val) => {});
            }
          });
      }
    },
    //解绑
    memberUnbind(item) {
      this.$msgBox
        .confirmMessageBox({
          content:
            "解除绑定账号会影响当前正在进行中的金标认证，确定解除当前绑定账户吗？",
        })
        .then(async (data) => {
          singleMembersSever
            .memberUnbind({
              memberId: item.id, //	必选	int	厂商id
            })
            .then((res) => {
              if (res.code == 0) {
                this.$store
                  .dispatch("membersStore/updateMembers", {})
                  .then((res) => {
                    //更新厂商绑定数据
                    if (res.code == 0) {
                      this.$message.success("解绑成功");
                    }
                  });
              } else {
                this.$message.error(res.message);
              }
            });
        })
        .catch((val) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.pageBox {
  padding: 20px;
  border-radius: 5px;
  min-height: calc(100% - 94px); //减底部(20px+ICP高度+20px)
  .content {
    min-height: 800px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(70, 25, 161, 0.1);
    .title {
      position: relative;
      display: flex;
      align-items: center;
      height: 100px;
      padding-left: 49px;
      font-size: 24px;
      font-weight: bold;
      line-height: 100%;
      color: rgba(201, 193, 153, 1);
      background: url(../../../assets/img/console/titleBg.jpg) no-repeat #28252c;
      background-position: 70% 0;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 33px;
        width: 5px;
        height: 24px;
        transform: translateY(-50%);
        border-radius: 3px;
        background: #ded094;
      }
    }
    .membersBox {
      padding: 0 33px;
      // min-height: 700px;
      .msg {
        margin: 34px 0 26px 0;
        font-size: 16px;
        color: rgba(102, 102, 102, 1);
      }
      .membersContent {
        .members {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .item {
            margin-bottom: 3%;
            width: 48%;
            height: 249px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(235, 235, 239, 1);
            border-radius: 5px;
            .memberName {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 66px;
              background: rgba(243, 243, 245, 1);
              border-bottom: 1px solid rgba(235, 235, 239, 1);
              .xiaomiLogo {
                width: 116px;
                height: 51px;
              }
              .OPPOLogo {
                width: 93px;
                height: 24px;
              }
              .vivoLogo {
                width: 101px;
                height: 25px;
              }
              .lenovoLogo{
                width: 120px;
                height: 100px;
              }
            }
            .memberBindBox {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              height: 182px;
              position: relative;
              .memberUser {
                display: flex;
                line-height: 58px;
                .text {
                  display: block;
                  margin-right: 22px;
                  max-width: 358px;
                  min-width: 300px;
                  height: 58px;
                  padding-left: 24px;
                  background: rgba(243, 243, 245, 1);
                  border: 1px solid rgba(223, 223, 223, 1);
                  font-weight: bold;
                }
                .btn {
                  display: block;
                  color: #999;
                  font-size: 16px;
                  cursor: pointer;
                }
              }
              .memberBind {
                text-align: center;
                .bindIcon {
                  margin-bottom: 18px;
                  width: 3rem;
                  height: 3rem;
                  cursor: pointer;
                }
              }
              .tipBox{
                position: absolute;
                right: 20px;
                bottom: 20px;
                .must{
                  color: #ea4646;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

